import { Tooltip } from '@flipgrid/flipkit';

import CustomTagStyles from '~/styles/components/Utility/components/CustomTag.css';

type Props = {
  children: React.ReactNode;
  icon: SVGElement | object;
  iconOnly?: boolean;
  label: string;
};

export const links = () => [{ rel: 'stylesheet', href: CustomTagStyles }];

const CustomTag = ({ children, label, icon, iconOnly }: Props) =>
<Tooltip placement="top" label={label}>
    <div className="customTag">
      <>
        {icon || null}
        {!iconOnly && children || null}
      </>
    </div>
  </Tooltip>;


export default CustomTag;